import axios from 'axios';

const service = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_API, 
  timeout: 30000, // request timeout
  headers: { 'Content-Type': 'application/json' },
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.data = JSON.stringify(config.data);
    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  },
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    return res;
  },
  (error) => {
    const rest = error.response;
    return Promise.reject(error);
  },
);

export default service;

<template>
  <div class="wallet-container">
    <h1 class="g-font-bold">
      <img src="../assets/images/logo_black.svg" alt="" />
      <span v-t="'nft_pool'" />
    </h1>
    <div class="redeem-only" v-if="isRedeemOnly">(Redeem/Repay only)</div>
    <ConnectWalletPage class="wallet-container__connect" />
  </div>
</template>

<script setup>
import ConnectWalletPage from "@/components/ConnectWallet/ConnectWalletPage.vue";
import { checkRedeemOnly } from "@/api/other";
import { ref, onMounted } from "vue";

const isRedeemOnly = ref(false);

onMounted(async () => {
  isRedeemOnly.value = await checkRedeemOnly();
})
</script>

<style lang="scss" scoped>
.wallet-container {
  margin: 1.8rem 0 0.8rem;
  position: relative;
}
.wallet-container__connect {
  position: absolute;
  right: 0;
  top: 0;
}

h1 {
  text-align: center;
  font-size: 0.32rem;
  line-height: 0.4rem;
  img {
    display: inline-block;
    width: 0.6rem;
    margin-right: 0.08rem;
    transform:translateY(-4px);
  }
}

.redeem-only {
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin-top: 8px;
  font-weight: 600;

  @media (max-width: 750px) {
    text-align: center;
  }
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import { CacheName } from "./utils/enum";

let routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("./view/HomePages/HomeLay.vue"),
    children: [
      {
        path: "",
        name: "HomeIndex",
        meta: { activeLabel: "HomeIndex" },
        component: () => import("./view/HomePages/HomeIndex.vue"),
      },
      {
        path: "user",
        name: "UserIndex",
        meta: { activeLabel: "UserIndex" },
        component: () => import("./view/HomePages/UserDashBoard.vue"),
      },
      {
        path: "airdrop",
        name: "UserAriDrop",
        meta: { activeLabel: "UserAirDrop" },
        component: () => import("./view/HomePages/UserAirDrop/UserAirDrop.vue"),
      },
    ],
  },
  {
    path: "/supply",
    name: "SupplyIndex",
    component: () => import("./view/SupplyPages/SupplyIndex.vue"),
  },
  {
    path: "/borrow",
    name: "BorrowHome",
    component: () => import("./view/BorrowPages/BorrowLay.vue"),
    children: [
      {
        path: "",
        redirect: "/borrow/borrow-list",
      },
      {
        path: "borrow-list",
        name: "BorrowIndex",
        meta: { activeLabel: "BorrowIndex" },
        component: () => import("./view/BorrowPages/BorrowIndex.vue"),
      },
      {
        path: "borrow-steps",
        name: "BorrowSteps",
        meta: { activeLabel: "BorrowIndex" },
        component: () => import("./view/BorrowPages/BorrowSteps.vue"),
      },
      {
        path: "repay-list",
        name: "RepayIndex",
        meta: { activeLabel: "RepayIndex" },
        component: () => import("./view/RepayPages/RepayIndex.vue"),
      },
      {
        path: "repay-steps",
        name: "RepaySteps",
        meta: { activeLabel: "RepayIndex" },
        component: () => import("./view/RepayPages/RepaySteps.vue"),
      },
      {
        path: "sale-workflow/:market/:contractAddress/:tokenId",
        name: "SaleWorkFlow",
        meta: { activeLabel: "RepayIndex" },
        component: () => import("./view/SalesWorkflow/SellStep.vue"),
      },
    ],
  },
  {
    path: "/nft",
    name: "NFTIndex",
    component: () => import("./view/NFTPages/NFTLay.vue"),
    children: [
      {
        path: "",
        redirect: "/nft/my-list",
      },
      {
        path: "my-list",
        name: "MyNFTList",
        component: () => import("./view/NFTPages/NftIndex.vue"),
      },
      {
        path: "my-nft-sale-workflow/:market/:contractAddress/:tokenId",
        name: "MyNFTSaleWorkFlow",
        component: () => import("./view/SalesWorkflow/SellStep.vue"),
      },
    ],
  },
  {
    path: "/auction",
    name: "AuctionIndex",
    component: () => import("./view/AuctionPages/AuctionLay.vue"),
    children: [
      {
        path: "",
        redirect: "/auction/auction-market",
      },
      {
        path: "auction-market",
        name: "AuctionMarket",
        meta: { activeLabel: "AuctionMarket" },
        component: () => import("./view/AuctionPages/AuctionMarket.vue"),
      },
      {
        path: "my-auction",
        name: "MyAuction",
        meta: { activeLabel: "MyAuction" },
        component: () => import("./view/AuctionPages/MyAuctionLay.vue"),
      },
      {
        path: "risk-factor",
        name: "RiskFactor",
        meta: { activeLabel: "RiskFactor" },
        component: () => import("./view/AuctionPages/RiskFactorLay.vue"),
      },
    ],
  },
  {
    path: "/collateral",
    name: "CollateralIndex",
    component: () => import("./view/CollateralBuyPages/CollateralBuy.vue"),
    children: [
      {
        path: "",
        redirect: "/collateral/collateral-market/boredapeyachtclub",
      },
      // {
      //   path: "collateral-list",
      //   name: "CollateralList",
      //   component: () =>
      //     import("./view/CollateralBuyPages/AssetList/CollateralAssetList.vue"),
      // },
      {
        path: "collateral-market/:currentName",
        name: "CollateralMarket",
        component: () =>
          import("./view/CollateralBuyPages/Market/NFTMarket.vue"),
      },
      {
        path: "pd-detail/:market/:contractAddress/:tokenId",
        name: "ProductDetail",
        component: () =>
          import("./view/CollateralBuyPages/BuyDetail/ProductDetail.vue"),
      },
      {
        path: "purchase-process/:market/:contractAddress/:tokenId",
        name: "PurchaseProcess",
        component: () =>
          import("./view/CollateralBuyPages/BuyAction/PurchaseStep.vue"),
      },
    ],
  },
  {
    path: "/test",
    name: "Test",
    component: () => import("./view/TestCss.vue"),
    children: [
      {
        path: "",
        redirect: "/test/test1",
      },
      {
        path: "test1",
        component: () => import("./view/TestRouter1.vue"),
      },
      {
        path: "test2",
        component: () => import("./view/TestRouter2.vue"),
      },
    ],
  },
  {
    path: "/s/:code",
    name: "ShareHome",
    component: () => import("./view/HomePages/HomeLay.vue"),
    children: [
      {
        path: "",
        name: "ShareHomeIndex",
        meta: { activeLabel: "HomeIndex" },
        component: () => import("./view/HomePages/HomeIndex.vue"),
      },
      {
        path: "user",
        name: "ShareUserIndex",
        meta: { activeLabel: "UserIndex" },
        component: () => import("./view/HomePages/UserDashBoard.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/",
  },
];

if (import.meta.env.VITE_APP_ONTOLOGY_NETWORK === "update") {
  routes = [
    {
      path: "/",
      name: "UpdatePage",
      component: () => import("./view/UpdatePage.vue"),
    },
  ];
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 };
  },
});

const targetRouters = [
  "BorrowSteps",
  "RepaySteps",
  "PurchaseProcess",
  "SaleWorkFlow",
];

router.beforeEach((to, from, next) => {
  const sessionAddress = sessionStorage.getItem(CacheName.Login);
  if (!sessionAddress && targetRouters.includes(to.name)) {
    next("/");
  } else {
    next();
  }
});

export default router;

<template>
  <div
    class="drop-down"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div class="main-title" @click="info.url && openPage(info.url)">
      <div class="names">
        {{ info.title }}
        <img
          class="hot-img"
          v-if="info.isNew"
          src="../../assets/images/hot_g.svg"
          alt=""
        />
      </div>
    </div>
    <transition name="pull-up">
      <div v-if="dropShowing && info.list" class="drop-list">
        <div class="minwidths">
          <div
            class="user-set"
            :class="{
              update: item.isUpdate,
            }"
            v-for="(item, index) in info.list"
            :key="index"
            @click="gopageNav(item)"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref } from "vue";

defineProps({
  info: Object,
});
const dropShowing = ref(false);

const gopageNav = (data) => {
  if (data.url === "/") return;
  if (data.target) {
    window.open(data.url, data.target);
  } else {
    window.open(data.url, "_self");
  }
};

const openPage = (url) => {
  if (url.indexOf("docs") < 0) {
    window.open(url, "_self");
  } else {
    window.open(url);
  }
};

const handleMouseEnter = () => {
  dropShowing.value = true;
};
const handleMouseLeave = () => {
  dropShowing.value = false;
};
</script>

<style lang="scss" scoped>
.drop-down {
  position: relative;
  height: 100%;
  margin: 0 auto;

  .main-title {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .names {
      color: rgba(255, 255, 255, 1);
      font-size: 0.15rem;
      height: 0.48rem;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      min-width: 0.6rem;
      transition: color 0.6s;
      cursor: pointer;
      font-weight: 600;
      &:hover {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  .drop-list {
    overflow: hidden;
    position: absolute;
    right: -60%;
    top: 70%;
    background: #1f1e5b;
    box-shadow: 0px 0px 20px rgba(96, 239, 251, 0.2);
    border-radius: 8px;

    .user-set {
      min-width: 2rem !important;
      padding: 0 0.24rem;
      text-align: left;
      color: #fff;
      border-bottom: 1px solid #22217e;
      border-top: 1px solid #22217e;
      cursor: pointer;
      transition: all 0.6s;
      word-break: break-all;

      &:hover {
        background: #22217E;
      }

      &:nth-of-type(n + 1) {
        border-top: none;
      }
    }

    .user-set {
      height: 0.48rem;
      line-height: 0.48rem;
      font-size: 0.12rem;
      text-align: center;
      position: relative;

      &.update::after {
        content: "";
        position: absolute;
        width: 40px;
        height: 20px;
        background: url(../../assets/images/subtract.svg) no-repeat center;
        top: 50%;
        transform: translate(4px, -50%);
      }
    }
  }
}

.pull-up-enter-active,
.pull-up-leave-active {
  transform: translateY(0);
  opacity: 1;
  transition: transform 300ms ease, opacity 300ms ease;
}

.pull-up-enter,
.pull-up-leave-active {
  opacity: 0;
  transform: translateY(20px);
}
</style>

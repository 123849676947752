import { useI18n } from "vue-i18n";
import { computed, ref, onMounted, h } from "vue";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
import {
  WalletType,
  CacheName,
  DefaultTimerLong,
  ReportEvent,
} from "@/utils/enum";
import { loadClient } from "../../utils/client";
import { useRouter, useRoute } from "vue-router";
import { getUserBalance, getUserInAuction, reportEvent } from "@/api/user";
import { getFp, getUrlParams } from "@/utils";
import { initWalletConnectProvider } from "../../utils/walletConnect";

export const useConnectWallet = () => {
  const router = useRouter();
  const route = useRoute();

  const store = useStore();

  const userAddress = computed(() => store.state.userAddress);

  const { t } = useI18n();
  const fingerprint = ref("");

  const isShowing = ref(false);
  const dialogRisk = ref(false);
  const dialogVisibleChoose = ref(false);

  const notifyObj = ref(null);
  const notifyNetObj = ref(null);

  const msgTitle = ref("");
  const targetChainId = ref("");

  const goRepay = () => {
    dialogRisk.value = false;
    router.push("/borrow/repay-list");
  };

  const targetSwitch = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: targetChainId.value }],
      });
    } catch (switchError) {
      notifyObj.value.close();
      ElNotification.error({
        title: t("switch_chain_failed"),
      });
    }
  };

  const isNetworkCorrect = async () => {
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    if (import.meta.env.VITE_APP_ONTOLOGY_NETWORK !== "main") {
      targetChainId.value = "0x5";
      msgTitle.value = t("not_kovan_nofitication");
    } else {
      targetChainId.value = "0x1";
      msgTitle.value = t("not_eth_nofitication");
    }
    if (chainId === targetChainId.value) {
      return true;
    }
    return false;
  };

  const displaySwitchWarning = () => {
    notifyObj.value = ElNotification.warning({
      title: msgTitle.value,
      duration: 0,
      message: h("p", null, [
        h("a", [
          h(
            "button",
            {
              class: "switch_btn",
              onClick: targetSwitch,
            },
            t("switch")
          ),
        ]),
      ]),
    });
  };

  const initEvent = () => {
    window.ethereum.on("accountsChanged", async (accounts) => {
      console.log("accountsChanged", accounts[0]);
      sessionStorage.setItem(CacheName.Login, accounts[0]);
      sessionStorage.setItem(CacheName.WalletType, WalletType.META_MASK);
      let code = sessionStorage.getItem(CacheName.ReferralCode);
      if (code) {
        await reportEvent(
          code,
          ReportEvent.ConnectWallet,
          fingerprint.value,
          accounts[0]
        );
      }
      ElNotification({
        title: "Notification",
        message: "Accounts Changed",
        type: "success",
      });
      router.go(0);
    });
    window.ethereum.on("chainChanged", async (result) => {
      console.log("chainChanged", result);
      if (notifyNetObj.value) {
        notifyNetObj.value.close();
        notifyNetObj.value = null;
      }
      notifyNetObj.value = ElNotification({
        title: "Notification",
        message: "Network Changed",
        type: "success",
      });
      if (!(await isNetworkCorrect())) {
        if (notifyObj.value) {
          notifyObj.value.close();
          notifyObj.value = null;
        }
        displaySwitchWarning();
      } else {
        notifyObj.value.close();
      }
    });
  };

  const handleChooseType = (type) => {
    console.log("type", type);
    if (type === WalletType.META_MASK) {
      handlerCollectMetaMask();
    }
    if (type === WalletType.WALLET_CONNECT) {
      handlerLinkWalletConnect();
    }
  };

  const handlerGetUserInAuction = async () => {
    if (userAddress.value) {
      const result = await getUserInAuction(userAddress.value);
      if (result) {
        dialogRisk.value = true;
      }
    }
  };

  const handlerLogin = () => {
    dialogVisibleChoose.value = true;
  };

  const handlerCollectMetaMask = async () => {
    if (typeof window.ethereum === "undefined") {
      return ElNotification.warning({
        title: t("notify_warn"),
        message: t("dis_meta_mask"),
      });
    }
    const client = await loadClient(WalletType.META_MASK);
    await client.eth.requestAccounts();
    const ethAddress = await client.eth.getAccounts();
    console.log("ethAddress", ethAddress);
    if (ethAddress.length > 0) {
      store.commit("SET_USER_ADDRESS", ethAddress[0]);
      store.commit("SET_USE_CLIENT", client);
      sessionStorage.setItem(CacheName.Login, ethAddress[0]);
      sessionStorage.setItem(CacheName.WalletType, WalletType.META_MASK);
      let code = sessionStorage.getItem(CacheName.ReferralCode);
      if (code) {
        await reportEvent(
          code,
          ReportEvent.ConnectWallet,
          fingerprint.value,
          ethAddress[0]
        );
      }
      router.go(0);
      if (!(await isNetworkCorrect())) {
        displaySwitchWarning();
      }
      initEvent();
    }
  };

  const handlerLinkWalletConnect = async () => {
    const provider = initWalletConnectProvider;
    //  Enable session (triggers QR Code modal)
    await provider.enable();
    const client = await loadClient(WalletType.WALLET_CONNECT);
    const accounts = await client.eth.getAccounts();
    const networkId = await client.eth.net.getId();
    console.log("accounts", accounts, "networkId", networkId);
    store.commit("SET_USER_ADDRESS", accounts[0]);
    store.commit("SET_USE_CLIENT", client);
    sessionStorage.setItem(CacheName.Login, accounts[0]);
    sessionStorage.setItem(CacheName.WalletType, WalletType.WALLET_CONNECT);
  };

  const signOut = async () => {
    isShowing.value = false;
    if (sessionStorage.getItem(CacheName.WalletType) === WalletType.WALLET_CONNECT) {
      await initWalletConnectProvider.disconnect();
    }
    store.commit("SET_USER_ADDRESS", "");
    store.commit("SET_USE_CLIENT", null);
    sessionStorage.clear();
    router.push("/");
  };

  const handlerGetBalance = async () => {
    if (userAddress.value) {
      const balance = await getUserBalance(userAddress.value);
      store.commit("SET_USER_WALLET_BALANCE", balance);
    }
  };

  onMounted(async () => {
    const sessionAddress = sessionStorage.getItem(CacheName.Login);
    const sessionWalletType = sessionStorage.getItem(CacheName.WalletType);
    if (sessionAddress && sessionWalletType === WalletType.META_MASK) {
      const client = await loadClient(WalletType.META_MASK);
      store.commit("SET_USER_ADDRESS", sessionAddress);
      store.commit("SET_USE_CLIENT", client);
      initEvent();
    }
    if (
      sessionWalletType === WalletType.META_MASK &&
      !(await isNetworkCorrect())
    ) {
      displaySwitchWarning();
    }
    if (sessionAddress && sessionWalletType === WalletType.WALLET_CONNECT) {
      const client = await loadClient(WalletType.WALLET_CONNECT);
      store.commit("SET_USER_ADDRESS", sessionAddress);
      store.commit("SET_USE_CLIENT", client);
    }
    handlerGetBalance();
    setInterval(() => {
      handlerGetBalance();
    }, DefaultTimerLong);
    await handlerGetUserInAuction();
    fingerprint.value = await getFp();
    let queryCode = getUrlParams("s");
    if (queryCode && window.performance.navigation.type !== 1) {
      sessionStorage.setItem(CacheName.ReferralCode, queryCode);
      await reportEvent(queryCode, ReportEvent.Invitation, fingerprint.value);
    }
  });
  return {
    userAddress,
    isShowing,
    dialogVisibleChoose,
    dialogRisk,
    goRepay,
    signOut,
    handlerLogin,
    handleChooseType,
  };
};

export default useConnectWallet;

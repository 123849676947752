<template>
  <div class="layout__header">
    <the-header />
  </div>
  <the-mobile-nav class="mobile_nav" />
  <div class="g-container">
    <wallet-container v-if="!isUpdate" />
    <route-tab class="mb60" v-if="!isUpdate" />
    <router-view />
  </div>
</template>
<script setup>
import { computed } from "vue";
import RouteTab from "./components/RouteTab.vue";
import TheHeader from "./components/TheHeader/TheHeader.vue";
import TheMobileNav from "./components/TheHeader/TheMobileNav.vue";
import WalletContainer from "./view/WalletContainer.vue";
const isUpdate = computed(() => {
  return import.meta.env.VITE_APP_ONTOLOGY_NETWORK === "update";
});
</script>

<style lang="scss" scoped>
.layout__header {
  background: #0E0D38;

  @media (min-width: 750px) {
    // pc
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 3;
  }
}
.mobile_nav {
  display: none;
  @media only screen and (max-width: 984px) {
    display: block;
  }
}
.g-container {
  padding-bottom: 1rem;
}
.mb60 {
  margin-bottom: 0.6rem;
}
</style>

import LinkURL from "@/utils/nav.js";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { queryNavList } from "../../api/nav";

const userHeader = () => {
  const { t } = useI18n();
  const navData = ref([]);
  const navList = computed(() => {
    return import.meta.env.VITE_APP_ONTOLOGY_NETWORK === "main"
      ? LinkURL.proUrl
      : LinkURL.devUrl;
  });

  const homeLink = navList.value.homeLink;

  onMounted(async () => {
    const list = await queryNavList();
    navData.value = [...list];
  });

  return {
    navData,
    homeLink,
  };
};

export default userHeader;

<template>
  <div class="connect-wallet">
    <div
      v-if="userAddress"
      class="address-content"
      @mouseenter="isShowing = true"
      @mouseleave="isShowing = false"
    >
      <div class="address">{{ formatLongStr(userAddress) }} <img src="../../assets/images/down_row.svg" alt=""></div>
      <transition name="pull-up">
        <div v-if="isShowing" class="user__menu">
          <div class="user__menu__item" @click="signOut">
            {{ t("sign_out") }}
          </div>
        </div>
      </transition>
    </div>
    <CommonButton v-else :label="t('collect_wallet')" @click="handlerLogin" />
    <CommonDialog v-model:visible="dialogVisibleChoose">
      <ChooseWalletType
        @handleChooseType="handleChooseType"
        @success="dialogVisibleChoose = false"
      />
    </CommonDialog>
    <CommonDialog v-model:visible="dialogRisk">
      <RiskWarning @goRepay="goRepay" />
    </CommonDialog>
  </div>
</template>

<script setup>
import CommonButton from "../CommonButton.vue";
import CommonDialog from "../CommonDialog.vue";
import ChooseWalletType from "./ChooseWalletType.vue";
import RiskWarning from "@/components/DialogAction/RiskWarning.vue";
import { useI18n } from "vue-i18n";
import { formatLongStr } from "@/utils/format";
import useConnectWallet from "./useConnectWallet.js";

const { t } = useI18n();

const {
  userAddress,
  isShowing,
  dialogVisibleChoose,
  dialogRisk,
  goRepay,
  signOut,
  handlerLogin,
  handleChooseType,
} = useConnectWallet();
</script>

<style lang="scss" scoped>
.address {
  padding: 0.1rem 0.2rem;
  color: var(--color-green);
  background: #1F1E5B;
  border-radius: 8px;
  img {
    margin-left: 0.1rem;
  }
}

.user__menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding-top: 5px;

  @media (max-width: 750px) {
    padding-top: 0.1rem;
  }
}

.user__menu__item {
  background: rgba(255, 255, 255, 0.08);
  line-height: 40px;
  font-size: 14px;
  color: var(--color-green);
  text-align: center;
  cursor: pointer;
  border-radius: 10px;

  &:hover {
    background: #1F1E5B;
  }

  @media (max-width: 750px) {
    line-height: 0.7rem;
    font-size: 0.24rem;
  }
}

.pull-up-enter-active,
.pull-up-leave-active {
  transform: translateY(0);
  opacity: 1;
  transition: transform 300ms ease, opacity 300ms ease;
}

.pull-up-enter,
.pull-up-leave-active {
  opacity: 0;
  transform: translateY(-20px);
}
</style>

import CryptoJS from "crypto-js";

const LoginKey = "token";

const storage =
  import.meta.env.VITE_APP_ONTOLOGY_NETWORK !== "main"
    ? sessionStorage
    : sessionStorage;

export function getToken() {
  return storage.getItem(LoginKey);
}

export function setToken(token) {
  return storage.setItem(LoginKey, token);
}

export function setNews(name, data) {
  return storage.setItem(name, data);
}
export function getNews(name) {
  return storage.getItem(name);
}
export function removeNews(name) {
  return storage.removeItem(name);
}
export function clear() {
  return storage.clear();
}
export function removeToken() {
  return storage.removeItem(LoginKey);
}

export const hmacSHA256 = (message, key) => {
  return CryptoJS.HmacSHA256(message, key).toString();
};

/**
 *
 * @param obj input params type of object
 * @returns Objects sorted by key
 */
export const sortParams = (obj) => {
  const customParams = {};
  Object.keys(obj)
    .sort()
    .forEach(function (key) {
      // @ts-ignore
      customParams[key] = obj[key];
    });
  return customParams;
};

/**
 *
 * @param obj input params
 * @returns String concatenated by &
 */
export const connectStr = (obj) => {
  let strArr = [];
  Object.keys(obj).forEach(function (key) {
    // @ts-ignore
    strArr.push(key + "=" + obj[key]);
  });
  return strArr.join("&");
};

export const cryptoInfo = (params, key) => {
  const customParams = sortParams(params);
  // console.log("customParams", customParams);
  let str = connectStr(customParams);
  // console.log("ConnectStr", str);
  return hmacSHA256(str, key);
};

import WalletConnectProvider from "@walletconnect/web3-provider";

// http://101.32.108.85:7545 https://mainnet.infura.io/v3/1339616228cd4fe3a3935457b82aebac
export const initParams = () => {
  if (import.meta.env.VITE_APP_ONTOLOGY_NETWORK !== "main") {
    return {
      infuraId: '1339616228cd4fe3a3935457b82aebac',
      chainId: 42,
    };
  }
  // https://goerli.infura.io/v3/c33a0fa6c48a40c29ff4a43272d9373d
  //
  return {
    rpc: {
      1: "https://eth.public-rpc.com",
    },
    chainId: 1,
  };
};

// export const initWalletConnectProvider = new WalletConnectProvider(
//   initParams(),
// );

export const initWalletConnectProvider = new WalletConnectProvider(initParams(),);

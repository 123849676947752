const devUrl = {
  homeLink: "http://43.134.54.166:32084",
  pools: "http://43.134.54.166:31294",
  inclusive: "http://43.134.29.209:32351/",
  governance: "http://43.134.54.166:32084/governance",
  crosschain: "http://43.134.54.166:31474",
  docs: "https://docs.wing.finance",
  Vote: "/vote",
  sushi: "http://43.134.29.209:31574/",
  ethpool: "http://43.128.102.123:31257/ethereum/",
  anypool: "http://172.168.3.239:31196/",
  binance_bridge: "https://www.binance.org/en/bridge",
  c_bridge: "https://cbridge.celer.network/#/transfer",
  anyswap_bridge: "https://bsc.anyswap.exchange/bridge#/router",
  okepool: "http://43.134.29.209:30621/okc",
  bscpool: "http://43.134.29.209:31149/bnbchain",
  poly_network: "https://bridge.poly.network/",
  brand: "http://43.134.54.166:32084/brand",
  tvloverview: "http://43.134.54.166:32084/tvloverview",
  wingdistribution: "http://43.134.54.166:32084/wingdistribution",
  currentreserve: "http://43.134.54.166:32084/currentreserve",
  recentliquidations: "http://43.134.54.166:32084/recentliquidations",
  ontology_evm: "http://43.134.29.209:31759/ontologyevm",
  ontology_bridge: "http://172.168.3.239:31496/",
  nft_pool: "http://43.134.54.166:31402/",
  asset_bridge: "http://43.134.29.209:30081/",
  aptos: "http://43.134.29.209:31007/aptos/",
};

const proUrl = {
  homeLink: "https://wing.finance/",
  pools: "https://flash.wing.finance/",
  inclusive: "https://inclusive.wing.finance/",
  governance: "https://wing.finance/governance",
  crosschain: "https://swap.wing.finance/",
  docs: "https://docs.wing.finance",
  Vote: "/vote",
  sushi: "https://liquidity.wing.finance",
  ethpool: "https://flash.wing.finance/ethereum/",
  anypool: "https://any.wing.finance/",
  binance_bridge: "https://www.binance.org/en/bridge",
  c_bridge: "https://cbridge.celer.network/#/transfer",
  anyswap_bridge: "https://bsc.anyswap.exchange/bridge#/router",
  okepool: "https://flash.wing.finance/okc",
  bscpool: "https://flash.wing.finance/bnbchain",
  poly_network: "https://bridge.poly.network/",
  brand: "https://wing.finance/brand",
  tvloverview: "https://wing.finance/tvloverview",
  wingdistribution: "https://wing.finance/wingdistribution",
  currentreserve: "https://wing.finance/currentreserve",
  recentliquidations: "https://wing.finance/recentliquidations",
  ontology_evm: "https://flash.wing.finance/ontologyevm",
  ontology_bridge: "https://bridge.ont.io/",
  nft_pool: "https://nft.wing.finance",
  asset_bridge: "https://bridge.wing.finance",
  aptos: "https://flash.wing.finance/aptos",
};

export default {
  devUrl,
  proUrl,
};

import { getTimes } from "@/utils/count.js";
import { cryptoInfo } from "../utils/auth.js";
import { getUuid } from "@/utils/index";

import request from "./request.js";
import { APP_ID, APP_KEY } from "@/utils/enum";
import axios from "axios";

export const getUserBalance = async (address) => {
  const result = await request({
    url: "/nft-pool/balance/" + address,
  });
  //   console.log("getUserBalance result", result);
  return result.result;
};

export const getUserSuppliedAssetBalance = async (address) => {
  const result = await request({
    url: "/nft-pool/user-supply/" + address,
  });
  // console.log("getUserSuppliedAssetBalance result", result);
  return result.result;
};

export const getUserOverviewData = async (address) => {
  const { result } = await request({
    url: "/nft-pool/user-overview/" + address,
  });
  // console.log("getUserOverviewData result", result);

  return {
    ...result,
    totalWingEarnedDollar: getTimes(
      result.totalWingEarned,
      result.wingPriceUsd
    ),
    borrowBalanceDollar: getTimes(result.borrowBalance, result.ethPriceUsd),
    insuranceBalanceDollar: getTimes(
      result.insuranceBalance,
      result.wingPriceUsd
    ),
    nftCollateralBalanceDollar: getTimes(
      result.nftCollateralBalance,
      result.ethPriceUsd
    ),
    // totalETHEarnedDollar: getTimes(result.totalETHEarned, result.ethPriceUsd),
    supplyBalanceDollar: getTimes(result.supplyBalance, result.ethPriceUsd),
  };
};

export const getNFTList = async (address) => {
  const result = await request({
    url: "/user-nft/nfts-info/" + address,
  });
  // console.log("getNFTList result", result);
  return result.result;
};

export const getUserInsureAssetBalance = async (address) => {
  const { result } = await request({
    url: "/nft-pool/user-insurance/" + address,
  });
  // console.log("getUserInsureAssetBalance result", result);
  return [result];
};

export const getUserMyBorrowData = async (address) => {
  const { result } = await request({
    url: "/user-nft/my-borrow",
    method: "POST",
    data: {
      address,
    },
  });
  // console.log("getUserMyBorrowData result", result);
  return result;
};

export const getUserBorrowAssetData = async (address) => {
  const { result } = await request({
    url: "/nft-pool/user-borrow/" + address,
    method: "GET",
  });
  // console.log("getUserBorrowAssetData result", result);
  return result;
};

export const getMyBids = async (
  address,
  selectNft,
  sort,
  pageNumber,
  pageSize
) => {
  const { result } = await request({
    url: "/auction/my-bid",
    method: "POST",
    data: {
      address,
      selectNft,
      sort,
      pageNumber,
      pageSize,
    },
  });
  // console.log("getMyBids result", result);
  return result;
};

export const getMyLiquidationList = async (
  address,
  selectNft,
  sort,
  pageNumber,
  pageSize
) => {
  const { result } = await request({
    url: "/auction/my-liquidation",
    method: "POST",
    data: {
      address,
      selectNft,
      sort,
      pageNumber,
      pageSize,
    },
  });
  // console.log("getMyLiquidationList result", result);
  return result;
};

export const getUserInAuction = async (address) => {
  const { result } = await request({
    url: "/auction/in-auction",
    method: "POST",
    data: {
      address,
    },
  });
  // console.log("getUserInAuction result", result);
  return result;
};

export const getReportTime = async () => {
  const { result } = await request({
    url: import.meta.env.VITE_APP_REPORT_API + "/statistic/system-time",
  });
  return result;
};

export const reportEvent = async (code, action, fingerprint, address) => {
  let params = {
    code,
    action,
    fingerprint,
  };
  if (address) {
    params = {
      code,
      action,
      fingerprint,
      address,
    };
  }
  params.nonce = getUuid();
  params.appId = APP_ID;
  params.time = await getReportTime();
  console.log("params", params);
  const messageMAC = cryptoInfo(params, APP_KEY);
  await axios.post(
    import.meta.env.VITE_APP_REPORT_API + "/statistic/report",
    params,
    {
      headers: {
        Authorization: messageMAC,
      },
    }
  );
};

export const getProxyAddress = async (address) => {
  const { result } = await request({
    url: "/exchange/proxy-address/" + address,
    method: "GET",
  });
  if (result === "0x0000000000000000000000000000000000000000") {
    return false;
  }
  return result;
};

export const getNonces = async (address) => {
  const { result } = await request({
    url: "/exchange/nonces/" + address,
    method: "GET",
  });
  return result;
};

export const sendUserOrder = async (data) => {
  const { result } = await request({
    url: "/exchange/create-order",
    method: "POST",
    data,
  });
  return result;
};

export const getUserSaleOrder = async (address) => {
  const { result } = await request({
    url: "/exchange/seller-nonce/" + address,
    method: "GET",
  });
  return result;
};

export const sendUserCancelOrder = async (contractAddress, id) => {
  const params = {
    contractAddress,
    id,
    appId: APP_ID,
  };
  params.appId = APP_ID;
  const messageMAC = cryptoInfo(params, APP_KEY);
  params.encodeData = messageMAC;
  const { result } = await request({
    url: "/exchange/cancel-listing",
    method: "POST",
    data: params,
  });
  return result;
};

export const getRiskFactorList = async (
  address,
  selectNft,
  sort,
  pageNumber,
  pageSize
) => {
  const { result } = await request({
    url: "/auction/alerted-users",
    method: "POST",
    data: {
      address,
      selectNft,
      sort,
      pageNumber,
      pageSize,
    },
  });
  console.log("getMyLiquidationList result", result);
  return result;
};

export const WalletType = {
  META_MASK: "metamask",
  ONTO_EX: "onto-ex",
  WALLET_CONNECT: "wallet-connect",
  COINBASE: "coinbase",
  META_X: "meta-x",
};

export const CacheName = {
  Login: "wing-login-address",
  WalletType: "wallet-type",
  ReferralCode: "Referral-code",
};

export const FormStep = {
  Form: "form",
  Pending: "pending",
  ApprovePending: "approve_pending",
  Success: "success",
  Failed: "failed",
  CollateralizeSuccessful: "collateralize_successful",
};

export const AuctionSelectOption = {
  Active: "active",
  Liquidated: "liquidated",
};

export const MyAuctionSelectOption = {
  MyBids: "my_bids",
  MyLiquidation: "my_liquidation",
};

export const DefaultTimer = 3000;
export const DefaultTimerMiddle = 5000;
export const DefaultTimerLong = 8000;

export const RESERVE_BALANCE = "0.05";

export const DEBT_PERCENT = "0.01";

export const REDEEM_LIMIT = "0.8";

export const BID_RATE = '1.004';

export const TokenFloat = {
  ETH: 18,
  pWING: 9,
  WETH: 18,
};

export const ContractAddress = {
  WETH: import.meta.env.VITE_APP_WETH,
  WETHGateway: import.meta.env.VITE_APP_WETHGateway,
  PUNKGateway: import.meta.env.VITE_APP_PUNKGateway,
  BWETH: import.meta.env.VITE_APP_BToken,
  pWING: import.meta.env.VITE_APP_WING,
  INSURANCE_POOL: import.meta.env.VITE_APP_INSURANCE_POOL,
  BEND_PROTOCOL: import.meta.env.VITE_APP_BEND_PROTOCOL,
  DEBT_TOKEN: import.meta.env.VITE_APP_DEBT_TOKEN,
  WRAPPED_PUNK: import.meta.env.VITE_APP_WRAPPED_PUNK,
  LEND_POOL: import.meta.env.VITE_APP_LEND_POOL,
  DOWN_PAYMENT: import.meta.env.VITE_APP_NFT_Downpayment,
  StrategyStandard: import.meta.env
    .VITE_APP_NFT_StrategyStandardSaleForFixedPrice,
  WingNFTExchange: import.meta.env.VITE_APP_NFT_WingNFTExchange,
  RedeemNFT: import.meta.env.VITE_APP_NFT_RedeemNFT,
  LendPoolAddressesProvider: import.meta.env
    .VITE_APP_NFT_LendPoolAddressesProvider,
  PUNK: import.meta.env.VITE_APP_NFT_PUNK,
  AirdropReceiverContract: import.meta.env.VITE_APP_Airdrop_Receiver_Contract,
};

export const AdapterAddress = {
  looksrare: import.meta.env.VITE_APP_NFT_LooksRareExchangeAdapter,
  opensea: import.meta.env.VITE_APP_NFT_SeaportAdapter,
  punk: import.meta.env.VITE_APP_NFT_punkAdapter,
  wing: import.meta.env.VITE_APP_NFT_wingAdapter,
  x2y2: import.meta.env.VITE_APP_NFT_X2Y2Adapter,
};

export const AdapterTitle = {
  looksrare: "LooksRare Exchange Downpayment Adapter WingNFT",
  opensea: "Seaport Downpayment Adapter WingNFT",
  // opensea: "Opensea Downpayment Adapter WingNFT",
  punk: "Punk Downpayment Adapter WingNFT",
  wing: "WingNFT Exchange Downpayment Adapter",
  x2y2: "X2Y2 Downpayment Adapter WingNFT",
  wingSale: "WingNFTExchange",
};

export const allMarketType = {
  looksrare: "looksrare",
  opensea: "opensea",
  punk: "punk",
  wing: "wing",
  x2y2: "x2y2"
};

export const NFTTypeSelectOption = {
  erc721: "erc721",
  punk: "punk",
};

export const PUNK_TYPE = "CRYPTOPUNKS";

export const EXTRACT_MAX =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935";

export const APP_KEY = import.meta.env.VITE_APP_APP_KEY;
export const APP_ID = import.meta.env.VITE_APP_APP_ID;

export const ReportEvent = {
  Invitation: "invitation",
  ConnectWallet: "connect-wallet",
};

export const SortDefinition = {
  ASC: "ASC",
  DESC: "DESC",
};

export const CopyrightFee = "0.0009";

export const AIRDROP_TYPE = {
  AIRDROP_TYPE_V1: 1,
  AIRDROP_TYPE_V2: 2,
  AIRDROP_TYPE_V3: 3,
};

export const CLAIM_TOKEN_TYPE = {
  ERC721: "erc721",
  ERC1155: "erc1155",
};

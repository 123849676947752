<template>
  <div>
    <CoinTitle :label="t('risk_warning')" />
    <div class="content">
      <img src="../../assets/images/risk_error.svg" alt="" />
      <div class="tips">
        {{ t("risk_warning_tips") }}
      </div>
    </div>
    <div>
      <CommonButton type="submit" :label="t('go_to_repay')" @click="goRepay" />
    </div>
  </div>
</template>

<script setup>
import CoinTitle from "@/components/CoinTitle.vue";
import CommonButton from "@/components/CommonButton.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const emits = defineEmits(["goRepay"]);

const goRepay = () => {
  emits("goRepay");
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 0.6rem;
}
.tips {
  text-align: center;
  margin: 0.2rem 0 0.4rem;
  font-size: 0.16rem;
  line-height: 0.2rem;
  color: var(--color-content__6);
}
</style>

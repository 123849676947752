<template>
  <div
    class="button"
    :class="{
      block: block,
      go: type === 'go',
      span: type === 'span',
      primary: type === 'primary',
      submit: type === 'submit',
      link: type === 'link',
      insure: type === 'insure',
    }"
    @click="clickHandler"
    role="button"
    :aria-disabled="disabled ? 'true' : 'false'"
    tabIndex="0"
    :aria-label="label"
  >
    <slot>
      <span class="button__label">{{ label }}</span>
      <i v-if="type === 'go'" class="iconfont iconArrow_Right icon--go"></i>
    </slot>
  </div>
</template>

<script setup>
const props = defineProps({
  label: { type: [String, Number], required: true },
  disabled: { type: Boolean, required: false, default: false },
  block: { type: Boolean, required: false, default: false },
  type: { type: String, required: false, default: "primary" },
});
const emit = defineEmits(["click"]);

const clickHandler = (e) => {
  if (props.disabled) {
    return;
  }
  emit("click", e);
};
</script>

<style lang="scss" scoped>
.button {
  display: inline-block;
  cursor: pointer;
  transition: opacity 0.3s, background-color 0.3s;
  outline: none;

  &.block {
    display: block;
  }

  &:not([aria-disabled="true"]) {
    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &.go {
    .button__label {
      vertical-align: middle;
      color: var(--color-green);
      font-size: 0.2rem;
      font-weight: 500;
      line-height: 0.2rem;

      @media (max-width: 750px) {
        font-size: 0.3rem;
        line-height: 0.4rem;
      }
    }

    &:not([aria-disabled="true"]) {
      &:hover,
      &:focus {
        opacity: 0.6;

        .icon--go {
          transform: translateX(0.1rem);
        }
      }
    }
  }

  &.span {
    padding: 0.07rem 0.2rem;
    line-height: 0.18rem;
    background: var(--green-background);
    color: rgba(0, 175, 124, 1);
    font-size: 0.14rem;

    @media (max-width: 750px) {
      font-size: 0.26rem;
    }
  }

  &.primary {
    padding: 0.1rem 0.2rem;
    border-radius: 0.08rem;
    border: 1px solid var(--color-simple-blue);
    color: var(--color-simple-blue);
    font-size: 0.14rem;
    line-height: 0.2rem;
    font-weight: 500;
    transition: all 0.3s;

    &:not([aria-disabled="true"]) {
      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }

    @media (max-width: 750px) {
      font-size: 0.26rem;
      line-height: 0.38rem;
    }
  }

  &.submit {
    font-size: 0.14rem;
    font-weight: 600;
    text-align: center;
    background: linear-gradient(90deg, #5d68f8 0%, #60effb 100%);
    height: 0.56rem;
    line-height: 0.56rem;
    width: 100%;
    color: #fff;
    border-radius: 0.08rem;

    &[aria-disabled="true"] {
      cursor: not-allowed;
      opacity: 0.4;
      background: #0e0d38;
      color: var(--color-content);
    }

    &:not([aria-disabled="true"]) {
      &:hover,
      &:focus {
        opacity: 0.6;
      }
    }

    @media (max-width: 750px) {
      font-size: 0.26rem;
      height: 0.9rem;
      line-height: 0.9rem;
    }
  }

  &.link {
    font-size: 0.12rem;
    line-height: 0.17rem;
    color: var(--color-green);

    &:not([aria-disabled="true"]) {
      &:hover,
      &:focus {
        opacity: 1;
        text-decoration: underline;
      }
    }

    @media (max-width: 750px) {
      font-size: 0.24rem;
      line-height: 0.28rem;
    }
  }

  &.insure {
    padding: 0.1rem 0.2rem;
    border-radius: 0.04rem;
    background: #c93e6c;
    font-size: 0.14rem;
    line-height: 0.2rem;
    font-weight: 500;
    transition: all 0.3s;

    &:not([aria-disabled="true"]) {
      &:hover,
      &:focus {
        opacity: 1;
        background: #ff2a70;
      }
    }

    @media (max-width: 750px) {
      font-size: 0.26rem;
      line-height: 0.38rem;
    }
  }
}

.icon--go {
  margin-left: 0.1rem;
  font-size: 0.14rem;
  color: var(--color-green);
  vertical-align: middle;
  transition: transform 0.3s;
  display: inline-block;

  @media (max-width: 750px) {
    font-size: 0.26rem;
  }
}
</style>

import {
  getIsInvalidInput,
  isGt,
  getUnitAmount,
  getMinus,
  getTimes,
  getFixed,
  getPlus,
  isLt,
} from "@/utils/count";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const fpPromise = FingerprintJS.load();

export const isNumber = (val) => typeof val === "number" && !Number.isNaN(val);

export const judgeBorrowNumber = (val, maxNumber) => {
  if (getIsInvalidInput(val)) {
    return true;
  }
  if (isGt(val, maxNumber)) {
    return true;
  }
  return false;
};

export const getFp = async () => {
  const fp = await fpPromise;
  const result = await fp.get();
  return result.visitorId;
};

export const getUuid = () => {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";
  return s.join("");
};

export const getUrlParams = (name, origin = null) => {
  let url = location.href;
  let temp1 = url.split("?");
  if (temp1.length <= 1) {
    return "";
  }
  let pram = temp1[1];
  let keyValue = pram.split("&");
  let obj = {};
  for (let i = 0; i < keyValue.length; i++) {
    let item = keyValue[i].split("=");
    let key = item[0];
    let value = item[1];
    obj[key] = value;
  }
  return obj[name];
};
export const getDomainParams = (name, verifyingContract, version = "1.0") => {
  return {
    chainId: import.meta.env.VITE_APP_CHAIN_ID,
    name,
    verifyingContract,
    version,
  };
};

export const getFinallyPrice = (sellerPrice, borrowAmount) => {
  const unitSellerPrice = getUnitAmount(sellerPrice);
  const AAVETradingFee = getTimes(borrowAmount, "0.0009");
  console.log("AAVETradingFee", AAVETradingFee);
  let a = getMinus(unitSellerPrice, borrowAmount);
  let b = getPlus(a, AAVETradingFee);
  if (isLt(b, "0")) {
    return getFixed("0", 18);
  }
  return getFixed(b, 18);
};
